.leaderboard_title {
    color: #42dbcb;
    font-weight: 700;
    letter-spacing: 2px;
}

.leaderboard_header_tabs {
    position: absolute;
    top: -40px;
}

.bg-none {
    background-color: transparent !important;
}

.first_rank {
    color: #FCD538;
    background: #393525;
    border-radius: 6px;
    padding: 20px;
    gap: 30px;
}

.second_rank {
    color: #FCD538;
    background: #393939;
    border-radius: 6px;
    padding: 20px;
    gap: 30px;
}

.third_rank {
    color: #FCD538;
    background: #393229;
    border-radius: 6px;
    padding: 20px;
    gap: 30px;
}

.first_rank img {
    width: 51px;
    height: 56px;
}

.second_rank img, .third_rank img {
    width: 51px;
    height: 56px;
}

.rank_icon {
    position: relative;
    top: -40px;
    right: -40px;
}

.spirit_point img {
    width: 30px;
    height: 30px;
}

.table_rank_image img {
    width: 46px;
    height: 46px;
    border: 1px solid #FCD32C;
    border-radius: 50px;
    margin-right: 20px;
}

.spirit_point {
    color: #FFFFFF;
}

.text-right {
    text-align: right;
}

.leaderboard_tab .row {
    gap: 20px;
}

.leaderboard_tab {
    margin: 0 50px;
}

.table {
    color: #FFFFFF;
    background-color: #2b2b2b;
    margin-top: 20px;
}

.table tbody tr td {
    border-bottom: 1px solid #414141;
}

.table_rank_data {
    background-color: #555555 !important;
    text-align: center;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
}
.account_recovery_select{
    background-color: #232323;
    color: gray;
    width: 100%;
    padding: 11px 16px;
    border: 1px solid gray;
    border-radius: 5px;
    font-size: 13px;
    outline: none;
}

.account_recovery_select:focus {
    border-color: #FCD32C;
}

.fs-14{
    font-size: 14px !important;
}
.material-textfield {
    position: relative;
}

.material-textfield .toggle-password {
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
}

.material-textfield label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #232323;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
}
.material-textfield input {
    width: 100%;
    font-size: 14px;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 11px 16px;
    color: gray;
    transition: 0.1s ease-out;
    background-color: #232323;
    caret-color: white;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #232323 inset;
    -webkit-text-fill-color: #969696;
    caret-color: white;
}

.material-textfield input:focus {
    border-color: #FCD32C;
}
.material-textfield input:focus + label {
    color: #FCD32C;
    top: 0;
    transform: translateY(-50%) scale(.9);
}
.material-textfield input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(.9);
}
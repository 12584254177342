
@font-face{
  src:url(../font/Roboto/Roboto-Bold.ttf);
  font-family: 'Roboto Bold';
}
@font-face{
  src:url(../font/Roboto/Roboto-Regular.ttf);
  font-family: 'Roboto Regular';
}
@font-face{ 
    src:url(../font/Poppins/Poppins-Regular.ttf);
    font-family: 'Poppins Regular';
}
@font-face{ 
  src:url(../font/Poppins/Poppins-Medium.ttf);
  font-family: 'Poppins Medium';
}
@font-face{ 
  src:url(../font/Poppins/Poppins-SemiBold.ttf);
  font-family: 'Poppins SemiBold';
}
@font-face{ 
  src:url(../font/Poppins/Poppins-Light.ttf);
  font-family: 'Poppins Light';
}
@font-face{ 
  src:url(../font/wonder-boys/Wonder-Boys-Personal-Use.ttf);
  font-family: 'Wonder Boys';
}
@font-face{
  src:url(../font/Oswald/Oswald-VariableFont_wght.ttf);
  font-family: 'Oswald';
}
@font-face{
  src:url(../font/oval-single/OvalSingle.otf);
  font-family: 'Oval Single';
}
@font-face{
  src:url(../font/Lequire/Lequire-Regular.ttf);
  font-family: 'Lequire';
}
:root{
  /* color */
  --green-color:#42DBCB;
  --light-green-color:#64DB42;
  --white-color:#fff;
  --yello-color:#FCD32C;
  --red-color:#E53F39;
  --orange-color:#FD8C11;
  --dark-green-color:#1A2B29;
  --dark-yellow-color:#2E2A18;
  --dark-green-shade1:#252E2D;
  --dark-green-shade2:#2C3534;
  --dark-grey-shade1:#1E1E1E;
  --dark-grey-shade2:#252525;
  --light-black-color:#1D1D1D;
  --light-yellow-color:#FBE693;
  --dark-orange-color:#2F2316;
  --body-color:#0D0B02;
  --dark-grey-shade3:#141414;


  /* Font Family */
  --Poppins-Regular-font:'Poppins Regular';
  --Poppins-Medium-font:'Poppins Medium';
  --Poppins-SemiBold-font:'Poppins SemiBold';
  --Poppins-Light-font:'Poppins Light';
  --Wonder-Boys-font:'Wonder Boys';
  --Oswald-font:'Oswald';
  --Oval-Single-font:'Oval Single';
  --Lequire:'Lequire';
  --Roboto-Bold:'Roboto Bold';
  --Roboto-Regular:'Roboto Regular';
}

body{
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    font-family: var(--Poppins-Regular-font);
    background-color: #0D0B02;
}
img{
    width: 100%;
}
.cursor-pointer{
  cursor: pointer;
}
.register_avatar .register_image{
  width: 50px !important;
}
.register_avatar {
  position: relative;
}

.checkmark {
  position: absolute;
  top: -13px;
  right: 15px;
  font-size: 25px;
  color: #ffff;
}
a{
    text-decoration: none;
}
.font-poppins{
  font-family: var(--Poppins-Regular-font);
}
.font-roboto-bold{
  font-family: var(--Roboto-Bold);
}
.font-roboto-regular{
  font-family: var(--Roboto-Regular);
}
.font-oswald{
  font-family: var(--Oswald-font) !important;
}
.font-oval-single{
  font-family: var(--Oval-Single-font) !important;
}
.font-8{
  font-size: 8px;
}
.font-10{
  font-size: 10px !important;
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-16{
  font-size: 16px;
}
.font-18{
  font-size: 18px;
}
.font-20{
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}
.font-25{
  font-size: 25px;
}
.font-27{
  font-size: 27px;
}
.font-30{
  font-size: 30px;
}
.font-34{
  font-size: 34px;
}
.font-50{
  font-size: 50px;
}
.font-60{
  font-size: 60px;
}
.yellow-heading{
  color:  var(--yello-color) !important;
}
.color-light-yellow{
  color: var(--light-yellow-color);
}
.color-light-blue{
  color: #1492E6;
}
.light-grey-button{
  background-color: #3B3B3B !important;
}
.background-body{
  background-color: var(--body-color) !important;
}
.grey-button{
  background-color: #535353;
}
.color-green{
  color: var(--green-color);
}
.yellow-border{
  border: 2px solid var(--yello-color) !important;
}
.color-red{
  color: var(--red-color);
}
.color-orange{
  color: var(--orange-color);
}
.background-orange{
  background-color: var(--orange-color);
}
.color-light-green{
  color: var(--light-green-color) !important;
}
.background-light-green{
  background-color: var(--light-green-color) !important;
}
.background-dark-green{
  background-color: var(--dark-green-color) !important;
}
.background-dark-green-shade1{
  background-color: var(--dark-green-shade1) !important;
}
.background-dark-green-shade2{
  background-color: var(--dark-green-shade2) !important;
}
.background-dark-grey-shade3{
  background-color: var(--dark-grey-shade3) !important;
}
.background-dark-grey-shade1{
  background-color: var(--dark-grey-shade1) !important;
}
.background-dark-grey-shade2{
  background-color: var(--dark-grey-shade2) !important;
}
.backgroud-light-black{
  background-color: var(--light-black-color) !important;
}
.background-dark-orange{
  background-color: var(--dark-orange-color) !important;
}
.background-grey{
  background-color: #3B3B3B;
}
.background-light-grey{
  background-color: #2B2B2B !important;
}
.background-dark-grey{
  background-color: #232323;
}
.background-dark-yellow{
  background-color: var(--dark-yellow-color);
}
/* Side Bar */
.sidebarMenu{
  width: 6.13%;
  min-height: 100vh;
  background-color: #26241C;
}
.bodyPart{
  width: 93%;
}
.side-nav .nav-menu {
  list-style: none;
  padding: 40px 0;
  /*min-height: 100vh;*/
}
.side-nav .nav-menu .active::after{
  content: "";
  height: 100%;
  background: var(--green-color);
  width: 10px;
  position: absolute;
  top: 0;
  left: -10px;
  border-radius: 20px;
  opacity: 1;
}
.side-nav .nav-item {
  position: relative;
  padding: 10px 13px;
  text-align: center;
  border-left: 3px solid transparent;
}
.nav-item.active a {
  color: var(--green-color);
}
.nav-item a {
  text-decoration: none;
  color: var(--white-color);
}
.side-nav .nav-menu .nav-item .message{
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: var(--yello-color);
  font-family: var(--Poppins-SemiBold-font);
  /*right: 25px;*/
  margin-top: -9px;
  border: 2px solid #26241C;
  color: #2D2F43;
}
.nav-item img{
  width: auto;
  display: block;
}
.side-nav .nav-menu .active .icon-white{
  display: none;
}
.side-nav .nav-menu .icon-green{
  display: none;
}
.side-nav .nav-menu .active .icon-green{
  display: block;
}

/* navbar */
.navbar .w-60{
  width: 60% !important;
}
.navbar{
  background-color: #1C1705 !important;
  padding-top: 0.3rem!important;
  padding-bottom: 0.1rem!important
}
.search_bar a{
  font-family: var(--Poppins-Medium-font);
}
.search_bar input:focus{
  background-color: #332F1E;
  color: var(--white-color);
}
.search_bar input {
  background-color: #332F1E;
  border: 0;
  outline: none;
  padding-right: 35px;
}
.search_bar input::placeholder{
  color: #9A9A9A;
  font-size: 13px;
  font-family: var(--Poppins-Regular-font);
}
.search_bar i{
  height: 100%;
  position: relative;
  right: 25px;
  z-index: 9;
  color: var(--yello-color);
}
.login a,
.register a{
  font-family: var(--Poppins-Medium-font);
}
.login a::before {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.399' height='12.666' viewBox='0 0 11.399 12.666'%3E%3Cpath id='login' d='M7.433,11.5V9.6H3V7.066H7.433v-1.9L10.6,8.333Zm0-9.5h5.7A1.27,1.27,0,0,1,14.4,3.267V13.4a1.27,1.27,0,0,1-1.267,1.267h-5.7A1.27,1.27,0,0,1,6.167,13.4V12.133H7.433V13.4h5.7V3.267h-5.7V4.533H6.167V3.267A1.27,1.27,0,0,1,7.433,2Z' transform='translate(-3 -2)' fill='%23fcd32c'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 22px;
  position: relative;
  top: 3px;
}
.login a {
  display: flex;
}
.register a::before{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.549' height='12.666' viewBox='0 0 12.549 12.666'%3E%3Cg id='Group_4' data-name='Group 4' transform='translate(-821.301 23.622)'%3E%3Cpath id='Path_7' data-name='Path 7' d='M198.841,48.8a2.667,2.667,0,1,0,2.667,2.667A2.667,2.667,0,0,0,198.841,48.8Zm1.143,3.048h-.762v.762a.381.381,0,1,1-.762,0v-.762H197.7a.381.381,0,0,1,0-.762h.762V50.32a.381.381,0,1,1,.762,0v.762h.762a.381.381,0,1,1,0,.762Z' transform='translate(632.343 -66.509)' fill='%23fcd32c'/%3E%3Ccircle id='Ellipse_1' data-name='Ellipse 1' cx='3.166' cy='3.166' r='3.166' transform='translate(823.676 -23.622)' fill='%23fcd32c'/%3E%3Cpath id='Path_8' data-name='Path 8' d='M152.069,65.98a3.621,3.621,0,0,1-3.433-4.773,4.307,4.307,0,0,1-2.712-.3h-.413a3.326,3.326,0,0,0-3.325,3.325v1.029a1.188,1.188,0,0,0,1.187,1.187h8.708a1.187,1.187,0,0,0,1.046-.627A3.62,3.62,0,0,1,152.069,65.98Z' transform='translate(679.115 -77.404)' fill='%23fcd32c'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 22px;
  position: relative;
  top: 3px;
}
.register a {
  display: flex;
}
.dropdown-menu[data-bs-popper]{
  right: 1%;
  top: 115%;
  left: auto;
  margin-top: 0 !important;
  margin-top: -10px !important;
  background-color: #2B2B2B;
}
.dropdown-menu .dropdown-item:hover {
  background-color: transparent!important;
}
.dropdown-menu li{
  cursor: pointer;
}
.dropdown-content a:hover {
  background-color: transparent !important;
}
.dropdown-menu::after {
  border-bottom: 8px solid #2B2B2B;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  right: 10%; /* position */
  position: absolute;
  top: -5px;
}
.status-dropdown{
  right: 1.9% !important;
  width: 30%;
}
.request-dropdown{
  width: 35%;
  right: 18% !important;
}
.notification-dropdown{
  width: fit-content;
  right: 21% !important;
}
.notification-pill{
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: var(--yello-color);
  font-family: var(--Poppins-SemiBold-font);
  top: -5px;
  padding-left: 3px;
  right: 0;
  border: 2px solid #26241C;
  color: #2D2F43;
}
.status-dropdown input::placeholder{
  color: #9A9A9A;
  font-size: 13px;
  font-family: var(--Poppins-Regular-font);
}
.avtar-tick{
  left: 40%;
}
#tutiGame .modal-body p{
  font-family: var(--Oval-Single-font);
  font-size: 20px !important;
}
#refer-earn-body .modal-body h5{
  font-family: var(--Wonder-Boys-font);
}

/* Player List */
.player_list ul li {
  display: inline-flex;
}
.player_list  img{
  width: auto;
  padding: 0px 4px;
}
.player_list .active .icon-white{
  display: none;
}
.player_list li .icon-green{
  display: none;
}
.player_list .active .icon-green{
  display: inline-block;
}
.player_list{
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.player_list ul li a {
  background-color: #232119;
  padding: 7px 15px;
  font-size: 11px;
  color: #B2B4C5;
  font-family: var(--Poppins-Medium-font);
}
.player_list ul li a svg {
  margin-right: 5px;
}
.player_list::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.player_list::-webkit-scrollbar-thumb {
  background: #888;
}
.player_list ul li.active a{
  color: var(--green-color);
}
#settings .form-switch .form-check-input:checked{
  background-color: var(--green-color) !important;
}

/* Recommended for you Slider Section */
.slider_sec{
  width: 100%;
}
.recommanded{
  background-image: url("../images/slider-img.png");
  height: 270px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
.swiper-slide img {
  width: 92px;
}
.swiper-button-next img, .swiper-button-prev img{
  width: 10px !important;
  top: 11px;
  /*position: relative;*/
}
.swiper-button-prev img{
  left: 13px;
}
.swiper-button-next img{
  left: 18px;
}
.swiper-button-prev{
  background-image: none !important;
  left: auto !important;
  right: 5rem !important;
  height: 40px !important;
  background-color: #00000033;
  width: 40px !important;
  border-radius: 50%;
}
.swiper-container {
  font-size: 10px;
  font-family: var(--Poppins-Regular-font);
}
.swiper-container h2{
  font-size: 18px;
  font-family: var(--Poppins-Medium-font);
}
.swiper-container p{
  font-size: 10px;
}
.swiper-button-next{
  background-image:none !important;
  left: auto !important;
  right: 2rem !important;
  height: 40px !important;
  background-color: #00000033;
  width: 40px !important;
  border-radius: 50%;
}
.swiper-button-next, .swiper-button-prev {
  /*top: 17% !important;*/
  position: absolute !important;
  /*top: 50% !important;*/
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  /*margin-top: -119px !important;*/
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 12px !important;
  font-weight: 900;
}
.rating {
  font-size: 9px;
  font-family: var(--Poppins-Medium-font);
  opacity: 0.7;
}
.rating img {
  width: auto;
}
.swiper-slide p{
  font-family: var(--Poppins-Medium-font);
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* horizontal divider */
.horizontal-divider {
  height: 2px;
  background-color: grey;
  margin: 3px;
}

#new-update-slider-button {
  background-color: #ffffff33;
  padding: 0px !important;
  /*margin-top: -47px !important;*/
}
.slick-slide img {
  display: inline-block !important;
}
/* Model Section */
.modal-dialog{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) !important;
  z-index: 99;
}
.modal-backdrop {
  opacity: 0.7 !important;
}

button.btn-close {
  background-color: #E15757;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -90px;
  left: 50%;
  width: 41px;
  height: 41px;
  border-radius: 50% !important;
  opacity: 1;
}
.btn-close{
  background: url("../images/icon/close.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.modal-header img{
  width: 22px;
}
#forgotPassword .modal-header img{
  width: 20px;
}
.modal-footer{
  cursor: pointer;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.dropdown-toggle::after {
  margin-right: -21px;
}
h4.modal-title, h4.page-title{
  font-size: 27px;
  font-family: var(--Oval-Single-font);
  margin: -4px;
}
.modal-content{
  background-color: #2B2B2B;
  position: relative;
}
h4.virtualogin{
  font-size: 16px;
}
.modal-body{
  background-color: #232323;
}
.modal-body fieldset {
  border: 1px solid #FCD32C;
  border-radius: 7px;
}
.modal-body fieldset legend {
  color: #FCD32C;
  float: none;
  width: auto;
  height: 3px;
  font-family: var(--Poppins-SemiBold-font);
  font-size: 12px;
  line-height: 0;
}

#profileModal .modal-body fieldset{
  border: 1px solid #606060;
  border-radius: 7px;
}
.modal-body fieldset input {
  border: 0px solid #FCD32C;
  border-radius: 7px;
}
input.password:focus{
  background-color: #3B3B3B;
}
input.password{
  background-color: #3B3B3B;
}
input.fill{
  background-color: #3B3B3B;
}
input.group-input-fill{
  background-color: #3B3B3B !important;
}
input.group-input-fill:focus{
  background-color: #3B3B3B;
  color: var(--white-color);
}
input.fill:focus{
  background-color: transparent;
  color: var(--white-color);
}
select.fill{
  background-color: #3B3B3B;
  color: var(--white-color);
}
.form-control:focus {
  border-radius: 7px;
  border-color: red;
  box-shadow:   0px 0px 5px #FCD32C;
}
.modal-body i {
  color: #9D9D9D;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -42px;
}
.login-btn{
  background-color: var(--green-color);
  padding: 10px 39px;
  border-radius: 7px;
  color: #2B2B2B;
  font-family: var(--Poppins-SemiBold-font);
  font-size: 13px;
}
a.no-btn{
  background-color: #707070;
  padding: 10px 39px;
  border-radius: 7px;
  color: var(--white-color);
  font-family: var(--Poppins-SemiBold-font);
  font-size: 13px;
}
.transparent-link {
  background-color: transparent;
  border: none;
}
button.no-btn-small{
  background-color: #707070;
  border: 1px solid #707070;
  padding: 10px 10px;
  border-radius: 7px;
  color: var(--white-color);
  font-family: var(--Poppins-SemiBold-font);
  font-size: 13px;
}
button.danger-btn{
  background-color: red;
  padding: 10px 39px;
  border-radius: 7px;
  color: var(--white-color);
  font-family: var(--Poppins-SemiBold-font);
  font-size: 13px;
}
.account h6 {
  color: #FCD32C;
  font-family: var(--Poppins-SemiBold-font);
  font-size: 16px;
}
.account p{
  opacity: 0.3;
  font-family: var(--Poppins-Regular-font);
  font-size: 13px;
}
.modal-footer img{
  width: 22px;
}
.coming-soon {
  background-image: url("../images/icon/Group 66.svg");
  opacity: 0.1
}
.button {
  height: 40px;
  position: relative;
  background-color: #393939;
}
.button label,
.button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.button input[type="checkbox"] {
  opacity: 0.011;
  z-index: 100;
  cursor: pointer;
}
.button input[type="checkbox"]:checked + label {
  border: 1px solid #FCD32C;
}
.button label {
  cursor: pointer;
  z-index: 90;
  color: white;
}
.image-profile,.image-upload {
  height: 102px;
  position: relative;
}
.image-profile label,
.image-profile input,
.image-upload label,
.image-upload input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-profile input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
  cursor: pointer;
}
.image-profile input[type="radio"]:checked + label {
  border: 4px solid #FCD32C;
}
.image-profile label {
  cursor: pointer;
  z-index: 90;
  color: white;
  border: 4px solid #87783900;
  width: 100px;
  height: 100px;
  padding: 3px !important;
}
.image-profile.active .check-icon:before{
  opacity: 1;
  transform: scale(1.7);
}
.image-profile .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url(../images/icon/border-right-arrow.svg);
  background-repeat: no-repeat;
  opacity: 0;
  width: 17px;
  height: 17px;
  background-size: cover;
  z-index: 999;
  left: 69px;
  transform: scale(1.7);
}

/* Start Avatar Style */
.avatar-profile,.image-upload {
  height: 102px;
  position: relative;
}
.avatar-profile label,
.avatar-profile input,
.avatar-upload label,
.avatar-upload input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.avatar-profile input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
  cursor: pointer;
}
.avatar-profile input[type="radio"]:checked + label {
  border: 4px solid #FCD32C;
}
.avatar-profile label {
  cursor: pointer;
  z-index: 90;
  color: white;
  border: 4px solid #87783900;
  width: 100px;
  height: 100px;
  padding: 3px !important;
}
.avatar-profile.active .check-icon:before{
  opacity: 1;
  transform: scale(1.7);
}
.avatar-profile .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url(../images/icon/border-right-arrow.svg);
  background-repeat: no-repeat;
  opacity: 0;
  width: 17px;
  height: 17px;
  background-size: cover;
  z-index: 999;
  left: 69px;
  transform: scale(1.7);
}

/* End Avatar Style */
.profile-pic {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  z-index: 90;
  color: white;
  border: 4px solid #87783900;
  padding: 3px !important;
  background-repeat: no-repeat;
}
.image-upload input[type="file"] {
  display: none;
  cursor: pointer;
}
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 28px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #525252;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch input:checked + .slider {
  background-color: #42DBCB;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.notification p{
  font-family: var(--Poppins-SemiBold-font);
  font-size: 15px;
}
.switch_border_buttom {
  border-bottom: 1px solid #424242;
  padding: 15px 0;
}
.input-wrapper input {
  width: 100%;
}
.music_progress {
  background: linear-gradient(to right, #E53F39 0%, #E53F39 40%, #444444 40%, #444444 100%);
  border-radius: 8px;
  height: 7px;
  width: 400px;
  outline: none;
  appearance: none;
}
.music_progress::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  appearance: none;
  background: var(--white-color);
  border: 3px solid #E53F39;
}
.sound_progress {
  background: linear-gradient(to right, #FB8C11 0%, #FB8C11 40%, #444444 40%, #444444 100%);
  border-radius: 8px;
  height: 7px;
  width: 400px;
  outline: none;
  appearance: none;
}
.sound_progress::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  appearance: none;
  background: var(--white-color);
  border: 3px solid #FB8C11;
}

/*
refere & Earn
*/
.invite-friend .card-body h5{
  font-family: var(--Wonder-Boys-font);
}
.refer-select{
  background: #232323;
  background-image: url('../../assets/images/icon/chevron-down.svg');
  background-position: 90% center;
  background-repeat: no-repeat;
  background-size: 13px;
}
#refer-img {
  width: 35px;
  padding-right: 10px;
  padding-left: 10px;
}

#refer-hr {
  padding-right: 10px; 
  padding-left: 10px; 
}

#avtar-img {
  width: 30px;
}

#header-profile {
  padding-right: 10px;
  padding-left: 10px;
}

#refer-earn-body {
   border-radius:.50rem !important;
   padding-top: 30px;
   padding-bottom: 0px;
}

#invite-friend {
  background-color: #232323;
}

.shop .card{
  background: none;
}
/* shop page */
.shop .card-body{
  background: #2D2D2D !important;
  background-image: url("../../assets/images/icon/card-background.png") !important;
  background-repeat:no-repeat;
  background-size:cover !important;
  background-position:center !important;
  border-radius: 15px;
}

.btn-hover {
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}
.btn-hover:hover {
  color: #2B2B2B;
}

.btn-hover.color-1 {
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
}

.btn-hover.color-2 {
  background-image: linear-gradient(to right, #ffc107, #ECFF07, #ffcd39, #FFBB5B);
}

.btn-hover.color-3 {
  background-image: linear-gradient(to right, #dc3545, #bb2d3b, #E53F39, #E15757);
}

.btn-hover.color-4 {
  background-image: linear-gradient(to right, #64DB42, #2bb673, #20c997, #2bb673);
}

.btn-hover.color-5 {
  background-image: linear-gradient(to right, #E53F39, #E53F39, #E15757, #db6d6d);
}

.btn-hover.color-6 {
  background-image: linear-gradient(to right, #FFBB5B, #ffcd39, #ECFF07, #ffc107);
}

/*
virtua-coin-wallet
*/

#virtua-coin-wallet-header {
  width: 34px;
}

#profile-header-right {
  margin: 12px -15px -15px 0px;
}

#profile-header-right-stepper-icon {
  width: 65px;
}

#profile-header-right-stepper-icon-arrow {
  width: 45px;
  margin: 0px 0px 0px -30px;
}

#profile-header-btn {
  border-color: #42dbcb; 
  color: #42dbcb;
}

#profile-header-btn-yellow {
  border-color: #FCD32C;
  color: #FCD32C;
}

/* 
leaderboard
*/

.leaderboard li.nav-item .active{
  background-color: #FB8C11 !important;
  color: var(--white-color) !important;
}
.leaderboard li.nav-item .nav-link{
  background-color: #CECECE;
  color: #505050;
}
.leaderboard-heading h1{
  color: var(--green-color);
  font-size: 27px;
}
.leaderboard-heading p{
  font-size: 13px;
}
.filter-rank li.nav-item .nav-link{
  background-color: transparent;
  color: #929292;
}
.filter-rank li.nav-item .active{
  border-radius: 6px;
}
.spirit-point{
  background-color: #393524;
  border-radius: 6px;
  position: relative;
}
.spirit-point p{
  color: #FBE693;
  font-size: 16px;
}
.spirit-point h6{
  font-family: var(--Oswald-Bold-font);
  font-size: 18px;
}
img.winer-img{
  right: -22px;
  top: -10px;
}
.spirit-point .col-md-9{
  border-left: 1px solid #5A5749;
}
.total-count h6{
  font-family: var(--Oswald-Bold-font);
  font-size: 20px;
}
.player-name{
  font-family: var(--Poppins-SemiBold-font);
  font-size: 16px;
}
td.player-name img {
  width: 46px;
}
.plalyer-list tr{
  border-bottom: 1px solid #414141;
}
th.player-number{
  font-family: var(--Poppins-Bold-font);
  font-size: 25px;
  font-weight: bold;
  background-image: url(../images/icon/number-list-bg.png);
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
}
th.player-number-yellow{
  font-family: var(--Poppins-Bold-font);
  font-size: 25px;
  font-weight: bold;
  background-image: url(../images/icon/yellow-background.png);
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  color: #000000;
}
.top-players{
  background: radial-gradient(#FFBB5B, #FB8C11);
}
tr.top-players th.player-number{
  font-family: var(--Poppins-Bold-font);
  font-size: 25px;
  background-image: url(../images/icon/top-players.png);
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  color: #000000;
}

/*
 friend request 
*/

#friend-request-divider {
  margin: -12px 30px 0px 20px;
}

#friend-request-footer-font {
  font-size: 13px;
  margin: 3px;
}

#friend-request-body-font {
  font-size: 13px;
  margin-left: 10px;
}

#friend-request-body {
  margin: 7px;
}

#friend-request-body-flag {
  width: 20px;
}

#friend-request-body-accept-btn {
  margin: 1px -15px 0px 5px
}

#friend-request-divider {
  opacity: 0.1;
  margin-top: 0px;
}

/* friends page */

/* Friend Page */
.friend-heading h1{
  font-size: 27px;
  font-family: var(--Wonder-Boys-font);
}
.friend-body-content ul.nav li.nav-item button.nav-link.active{
  background-color: #FFBB5B !important;
  color: #232323 !important;
}
.border-right-radius{
  border-radius: 0 10px 10px 0 !important;
}
.border-left-radius{
  border-radius: 10px 0 0 10px !important;
}
.friend-body-content li.nav-item button.nav-link{
  border-radius: 0;
  background-color: #26241C;
  width: 194px;
  font-size: 15px;
  font-family: var(--Poppins-SemiBold-font);
  padding: 0.7rem 1rem;
}
.add-friend-btn button{
  background-color: var(--green-color);
  border-radius: 7px;
  padding: 10px;
  font-family: var(--Poppins-SemiBold-font);
  color: #2B2B2B;
  font-size: 13px;
  display: block;
  margin: 0 auto;
  width: 100%;
}
.table-search i {
  height: 100%;
  position: relative;
  right: 25px;
  z-index: 9;
  color: var(--white-color);
}
.table-search input:focus {
  background-color: #3B3B3B;
}
.table-search input {
  background-color: #3B3B3B;
  border: 0;
}
.table-top-search {
  background-color: #232323;
  padding: 1rem 1rem;
}
td.unfriend-btn button {
  background-color: #E53F39;
  display: block;
  font-family: var(--Poppins-Medium-font);
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  margin: 0 auto;
  width: 100%
}
td.accept-btn button {
  background-color: #64DB42;
  display: block;
  font-family: var(--Poppins-Medium-font);
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  color: #2B2B2B;
  margin: 0 auto;
  width: 100%;
}
td.reject-btn button {
  background-color: #E53F39;
  display: block;
  font-family: var(--Poppins-Medium-font);
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  color: #2B2B2B;
  margin: 0 auto;
  width: 100%;
}
td.green-color {
  color: #64DB42;
  font-family: var(--Poppins-Italic-font);
  font-size: 13px;
}
td.pink-color{
  color: #FF749F;
  font-size: 13px;
  font-family: var(--Poppins-Medium-font);
}
td.yellow-color{
  color: var(--yello-color);
  font-size: 13px;
  font-family: var(--Poppins-Medium-font);
}
td.dark-green-color{
  color: var(--green-color);
  font-size: 13px;
  font-family: var(--Poppins-Medium-font);
}
.add-block-list button{
  background-color: #E53F39;
  border-radius: 7px;
  padding: 10px;
  font-family: var(--Poppins-SemiBold-font);
  color: var(--white-color);
  font-size: 13px;
  display: block;
  margin: 0 auto;
  width: 100%;
}
td.unblock-btn button {
  background-color: var(--green-color);
  display: inline-block;
  font-family: var(--Poppins-Medium-font);
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 7px;
  color: #232323;
}
td.unblock-btn {
  text-align: right;
}

/* chat page start css*/

.chat-body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 648px) {
  .chat-body {
    overflow-x: auto;
  }
}
@media screen and (max-width: 460px) {
  .sidebarMenu{
    width: 15%;
  }
  .bodyPart{
    width: 85%;
  }
  .chat-body::after {
    display: none;
  }
}
.chat-content {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
}
.sidebar-chat {
  overflow: hidden;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.player-chat-body {
  -webkit-transform-origin: left top;
  transform-origin: left top;
}
@media screen and (max-width: 1024px) {
  ._2yeJ5 {
    border-left: 1px solid transparent;
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
  .two .sidebar-chat-left {
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
  }
  .two .player-chat-body {
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .two .sidebar-chat-left {
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
  }
  .two .player-chat-body {
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
  }
}
@media screen and (min-width: 1301px) {
  .two .sidebar-chat-left {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
  }
  .two .player-chat-body {
    -webkit-flex: 0 0 70%;
    flex: 0 0 70%;
  }
}
@media screen and (max-width: 900px) {
  .three .sidebar-chat-left {
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
  }
  .three .player-chat-body {
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
  }
}
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .three .sidebar-chat-left {
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
  }
  .three .player-chat-body {
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .three .sidebar-chat-left {
    -webkit-flex: 0 0 65%;
    flex: 0 0 65%;
  }
  .three .player-chat-body {
    -webkit-flex: 0 0 35%;
    flex: 0 0 35%;
  }
}
@media screen and (min-width: 1301px) {
  .three .sidebar-chat-left {
    -webkit-flex: 0 0 70%;
    flex: 0 0 70%;
  }
  .three .player-chat-body {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
  }
}
.selectable-text {
  font-size: 14px;
}

.selectable-text::after {
  content: "";
  display: inline-block;
  width: 0;
}
.selectable-text::after::-moz-selection {
  background-color: transparent;
}
.selectable-text::after::selection {
  background-color: transparent;
}
.sidebar-chat-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.14);
}
.chat-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow-y: auto;
  z-index: 1;
  background-color: #0D0B02;
  position: relative;
}
.chat-footer {
  min-height: 62px;
  -webkit-order: 3;
  order: 3;
  position: relative;
  -webkit-flex: none;
  flex: none;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.chat-footer-scroll {
  -webkit-align-items: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  max-width: 100%;
  min-height: 62px;
  position: relative;
  z-index: 2;
  background-color: #0D0B02;
  padding: 5px 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-top: 1px solid #201F18;
}
.send-msg {
  box-sizing: border-box;
  min-height: 52px;
  padding: 5px 10px;
}
.players-chat-scroll {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.players-chat-scroll textarea{
  width: 100%;
  resize: none;
  min-height: 46px;
  position: relative;
  z-index: 2;
  height: 34px;
  background-color: transparent;
  border-color: transparent;
  border-radius: 21px;
  border-style: solid;
  border-width: 1px;
  padding: 9px 12px 11px 12px;
  outline: none;
  color: var(--white-color);
}
.players-chat-scroll textarea::placeholder{
  font-family: var(--Poppins-Bold-font);
  font-size: 15px;
}
.player-chat-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: #050400;
  -webkit-transform-origin: left top;
  transform-origin: left top;
}
.players-chat-body {
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-order: 2;
  order: 2;
  position: relative;
  z-index: 1;
}
._2tOh4 {
  z-index: 111;
  -webkit-flex: none;
  flex: none;
}
.chat-heading {
  padding: 16px 16px;
  color: white;
  font-family: var(--Wonder-Boys-font);
  text-transform: uppercase;
}
input.chat-search:focus{
  background-color: #28261E;
}
input.chat-search {
  background-color: #28261E;
  margin: 0 0px 0 15px;
  padding: 15px;
}
.player-name {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--Poppins-Bold-font);
  font-size: 13px;
}
.chat-msg {
  display: inline-block;
  font-family: var(--Poppins-Regular-font);
}
.player-pic {
  position: relative;
  border-radius: 50%;
  background-color: #dfe5e7;
}
.player-chat-profile {
  -webkit-flex: none;
  flex: none;
  margin-top: -1px;
  padding: 0 15px 0 0;
}
.player-chat-header {
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  height: 59px;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  background-color: #0D0B02;
  padding: 10px 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}
.player-chat-header:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: -1px;
  background-color: rgba(255, 255, 255, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  left: 0;
}
.player-name-header {
  flex-grow: 1;
  flex-direction: column;
}
.player-name-chat-body {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-align: left;
}
#selectGame .modal-dialog,
#selectGame .modal-content {
  max-height: 90%;
}

#selectGame .modal-body {
  max-height: calc(100% - 80px);
  overflow-y: scroll;
}
.select_game_modal{
  overflow-y: auto;
}
#selectGame a.active .row{
  background-color: #353224 !important;
  border: 1px solid #FCD32C !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 1024px) {
  .player-chat-header {
    border-left: 1px solid #e0e0e0;
  }
}
@media screen and (min-width: 1301px) {
  .player-chat-header {
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
}
.game-player-chat {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  transition: background 0.3s ease-out 0.1s;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.03);
  left: 0;
}
.wml2- {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 12px;
}
.char-header-menu {
  -webkit-flex: none;
  flex: none;
  height: 24px;
  position: relative;
  transition: background-color 0.18s ease;
}
.char-header-menu i {
  color: #42DBCB;
  font-size: 24px;
}
@media screen and (max-width: 720px) {
  .char-header-menu {
    padding: 6px;
  }
  .char-header-menu {
    margin-left: 2px;
  }
}
.chat-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  overflow-y: auto;
}
.game-player-chat::-webkit-scrollbar-thumb {
  background: #201F18;
}
.game-player-chat::-webkit-scrollbar-track {
  background: #201F18;
}
.game-player-chat::-webkit-scrollbar{
  width: 2px;
}
.chat-list::-webkit-scrollbar-thumb,
.chat-scroll::-webkit-scrollbar-thumb {
  background: #201F18;
}
.chat-list::-webkit-scrollbar-track,
.chat-scroll::-webkit-scrollbar-track {
  background: #201F18;
}
.chat-list::-webkit-scrollbar,
.chat-scroll::-webkit-scrollbar{
  width: 0px;
}
.players-chat-scroll textarea::-webkit-scrollbar{
  width: 0px;
}
.chat-last-time {
  padding: 0 0 24px 0;
}
.chat-last-date p {
  color: #2A2926;
  letter-spacing: 7px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.chat-last-date span {
  width: 100% !important;
  text-align: center;
}
span.active-status {
  position: relative;
  background-color: #64DB42;
  width: 10px;
  height: 10px;
  top: -17px;
  z-index: 99999;
  display: block;
  bottom: 0;
  left: 34px;
  right: 0;
  border-radius: 50%;
  border: 2px solid #0D0B02;
}
.chat-last-date p:before,
.chat-last-date p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #2A2926;
}
.players-chat {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-bottom: 8px;
  transition: padding-left 0.3s cubic-bezier(0.69, 0, 0.79, 0.14);
  padding-left: 0;
}
.chat-time {
  text-transform: capitalize;
}
._2_LEW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.chat-player {
  position: absolute;
  width: 100%;
}
.chat-player:hover {
  z-index: 10000;
}
.player-out-msg {
  position: relative;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
.player-out-msg:after {
  clear: both;
  content: "";
  display: table;
}
@media (max-width: 1216px) and (min-width: 1152px) {
  .player-out-msg {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
}
@media (max-width: 1152px) and (min-width: 1088px) {
  .player-out-msg {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (max-width: 1088px) and (min-width: 1025px) {
  .player-out-msg {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}
@media (max-width: 1024px) {
  .player-out-msg {
    padding-left: 7%;
    padding-right: 7%;
  }
}
@media (max-width: 968px) {
  .player-out-msg {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
}
@media (max-width: 912px) {
  .player-out-msg {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (max-width: 856px) {
  .player-out-msg {
    padding-left: 5.5%;
    padding-right: 5.5%;
  }
}
@media (max-width: 800px) {
  .player-out-msg {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.chat-msg-color {
  font-size: 14.2px;
  line-height: 19px;
  color: #fff;
  position: relative;
  border-radius: 15px 15px 0 15px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.chat-msg-color:last-of-type {
  margin-bottom: 0;
}
.message-in {
  background-color: #1E1A04;
}
.message-in {
  float: left;
}
.message-out {
  float: right;
}
.message-in {
  border-top-left-radius: 0px;
}
.message-out {
  border-top-right-radius: 0px;
}
.tail-container {
  content: "";
  position: absolute;
  top: 0px;
  width: 12px;
  height: 19px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.message-in .tail-container {
  left: -12px;
}
.message-out .tail-container {
  right: -12px;
}
.tail-container.highlight {
  opacity: 0;
}
@media screen and (max-width: 900px) {
  .chatmsg-width {
    max-width: 95%;
  }
}
@media screen and (min-width: 901px) and (max-width: 1024px) {
  .chatmsg-width {
    max-width: 85%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .chatmsg-width {
    max-width: 75%;
  }
}
@media screen and (min-width: 1301px) {
  .chatmsg-width {
    max-width: 65%;
  }
}
.player-last-seen {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.player-last-seen {
  color: rgb(255 255 255 / 50%);
  font-size: 13px;
  line-height: 20px;
  min-height: 20px;
}
.game-player-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  font-family: var(--Poppins-Medium-font);
  font-size: 11px;
}
.chat-player-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 72px;
  pointer-events: all;
  position: relative;
  background-color: #0D0B02;
  cursor: pointer;
  border-left: 4px solid transparent;
}
.player-profile {
  -webkit-flex: none;
  flex: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-top: -1px;
  padding: 0 15px 0 13px;
}
.player-msg {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  min-width: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  padding-right: 15px;
}
.player-last-msg {
  -webkit-align-items: center;
  align-items: center;
  line-height: normal;
  display: -webkit-flex;
  display: flex;
  text-align: left;
}
.player-msg-show {
  -webkit-align-items: center;
  align-items: center;
  color: rgb(255 255 255 / 50%);
  display: -webkit-flex;
  display: flex;
  font-size: 13px;
  line-height: 20px;
  min-height: 20px;
  margin-top: 2px;
}
.player-last-msg-show {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  overflow: hidden;
  text-align: left;
}
.player-messages {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  text-align: left;
  -webkit-flex: none;
  flex: none;
}
.playerchat-time {
  color: #606060;
  -webkit-flex: none;
  flex: none;
  font-size: 12px;
  max-width: 100%;
  overflow: hidden;
  margin-left: 6px;
}
.player-last-msg .playerchat-time {
  line-height: 14px;
  margin-top: 3px;
}
.chat-player-content:hover {
  background-color: #1E1C15;
}
.chat-player-content:hover .player-msg {
  border-top-color: #f4f5f5;
}
.chat-player-content:hover:after,
.chat-player-content._1f1zm:after {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 0;
  content: "";
  left: 0;
}
.chat-player-content._1f1zm {
  background-color: #1E1C15;
  border-left: 4px solid #FCD32C;
}
.out-msgs {
  box-sizing: border-box;
  padding: 6px 7px 8px 9px;
}
.message-out .out-msgs{
  background-color: #0a1b15;
  border-radius: 15px 15px 0 15px;
}

.player-chat-games .message-out .out-msgs{
  background-color: #0a1b15;
  border-radius: 15px 0 15px 15px;
}
.game-player-chat-msg {
  position: relative;
  white-space: inherit;
  word-wrap: break-word;
}
.game-player-chat-msg::after {
  content: "";
  display: inline;
  margin-right: 78px;
}
.msg-out-time {
  color: #B7B7B7;
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  white-space: nowrap;
}
._1ORuP {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 15px;
  vertical-align: top;
  margin-left: 1px;
  margin-right: 2px;
}
/*! CSS Used from: Embedded */
#app{
  height:-webkit-fill-available;
  width:-webkit-fill-available;
  overflow:hidden;
  padding:0;
  position:absolute;
  margin:0;}

/* chat page end end*/
@media only screen and (max-width: 1024px) {
  .side-nav .nav-item{
    padding: 10px 0;
  }
}

/* coming soon page */
#coming-soon{
  background-image: url(../images/icon/ComingSoon.png);
  background-repeat: no-repeat;
  background-size: cover;
}
#grad {
  background-image: linear-gradient(to right, #142B37 , #3A2205);
}
.coming-soon-header{
  font-size: 85px;
  color: var(--green-color);
  font-family: var(--Lequire);
}
.coming-soon-form .btn-warning{
  font-size: 13px !important;
  color: #000;
  padding: 10px;
}
.coming-soon-form ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

/* info page */
.info ul{
  border-color: #707070;
}
.info ul li{
  padding-right: 2px;
  padding-bottom: 1px;
}
.info ul li button{
  width: 120px;
  background-color: #26241C !important;
  border-radius: 0 !important;
  border:none !important;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
}
.info ul li button:hover{
  color: #ffffff;
}
.info ul li:first-child button{
  border-radius: 5px 0 0 0 !important;
}
.info ul li:last-child button{
  border-radius: 0 5px 0 0 !important;
}
.info ul li button.active{
  background-color: #FFBB5B !important;
  color: #000 !important;
  font-size: 15px;
  font-weight: bold;
}
#info table tbody > tr:last-child >td{
  border-bottom: 0;
}
.game-tabs ul li button.active{
  border-bottom: 3px solid var(--yello-color) !important;
  background-color: transparent !important;
  color: var(--yello-color) !important;
  font-size: 13px;
}
.game-tabs ul li.active{
  border-bottom: 3px solid var(--yello-color);
}
.game-tabs ul li button.active:hover{
  color: var(--yello-color) !important;
}
.game-tabs ul li button{
  background-color: transparent !important;
  font-size: 13px;
  color: #707070 !important;
  border: none !important;
}
/*.game-tabs ul li button:hover{*/
/*  color: #707070 !important;*/
/*  border: none !important;*/
/*}*/
#myTabContentGames .card{
  background-color: #161616;
  cursor: pointer;
}
#myTabContentGames .card.active{
  border: 1px solid #fb8c11 !important;
}
.popular-button{
  background-color: #fb8c11;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  width: 160px;
  border: none;
  border-radius: 0 0 18px 18px;
  margin-top: -5px;
}
.message-1{
  border-radius: 15px 0 15px 15px;
}
.message-2{
  border-radius: 0 15px 15px 15px;
}
.line{
  text-align:center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 10px 0;
  grid-gap: 10px;
}
.line:after,.line:before {
  content: " ";
  display: block;
  border-bottom: 1px solid #707070;
}
.player-online .img1{
  z-index: 15;
  position: relative;
}
.player-online .img2{
  z-index: 10;
  position: relative;
  margin-left: -15px;
}
.player-online .img3{
  z-index: 5;
  position: relative;
  margin-left: -15px;
}
.player-online .img4{
  z-index: 1;
  position: relative;
  margin-left: -15px;
}
.player-progress{
  --bs-progress-height: 10px !important;
  --bs-progress-bg: #707070 !important;
}
.profile-progress{
  --bs-progress-height: 10px !important;
  --bs-progress-bg: #707070 !important;
}
#myTabContentGamesDescription .tab-pane{
  height: 265px;
}
#myTabContentGamesDescription textarea{
  background-color: #555555 !important;
  border: none !important;
  resize: none;
}
.description-content{
  overflow-y: scroll;
  height: 265px;
}
#gameDescription .nav-tabs{
  --bs-nav-tabs-border-color: #707070 !important;
}
.profileMenu.dropdown-menu::after{
  border: none !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  height: 50px;
  background: #707070;
  border-radius: 10px;
}

/* insta-wallet page */
#myTabContentInstaWallet .card{
  background-color: #26241C;
}
.small-game-div img{
  padding: 3px;
  cursor: pointer;
}
.small-game-div img.active{
  border: 3px solid #FCD32C;
  border-radius: 9px;
}

/* Weekly reward page */
.games{
  cursor: pointer;
}
.games.active{
  background-color: #26241C;
  border-radius: 10px;
}
.gray-background-image{
  background-image: url("../images/icon/number-list-bg.png");
  background-repeat: no-repeat;
}
.yellow-background-image{
  background-image: url("../images/icon/yellow-background.png");
  background-repeat: no-repeat;
}
.yellow-gradient{
  background-image: radial-gradient(#FFBB5B, #fb8c11);
  border: 2px solid #ffffff;
  border-radius: 8px;
}

  .floating-label {
    position: absolute;
    pointer-events: none;
    top: -20px;
    transition: 0.2s ease all;
  }
  .form-float input:placeholder-shown ~ label {
    top: 0;
    visibility: hidden;
  }

  .form-float {
    position: relative;
  }


input#date_of_birth {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.virtuacoin_wallet .virtuacoin_wallet_image img{
  width: 17px;
  height: 14px;
}

.field{
  position: relative;
}

.field input{
    padding: 0 10px 0 45px!important;
    height: 40px;
    color: #FFFFFF;
}

.virtuacoin_wallet .virtua_coin_image{
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.field input::-webkit-outer-spin-button,
.field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.field input[type=number] {
    -moz-appearance: textfield;
}

.remove_virtua_coin img{
  width: 27px;
  height: 30px;
}

.header_vcoin img{
  width: 30px;
  height: 30px;
}

.remove_virtua_coin{
  display: flex;
  align-items: center;
  gap: 30px;
}